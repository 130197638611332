import { Page, Pagination } from 'common/interfaces/common';
import { Extended } from 'common/types/extended/extended';
import { Api } from 'modules/api/api';
import { queryCache } from 'react-query';

import { QueryBuilder } from '../entity/query/builder/builder';
import { EntityRelation } from '../entity/relation/relation';
import { LocHub } from '../lochub';
import { ProjectId } from '../projects/project/id/id';
import { ProjectDto } from '../projects/project/project';
import { AutomationDto, NewAutomation } from './automation/automation';
import { AutomationId } from './automation/id/id';

export type AutomationDtoWithProject = Extended<AutomationDto, { project: ProjectDto }>;

export class Automations {
  public static readonly path: string = '/api/internal/automations';
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Automations.path, parameters);
  }

  private static getDetailUrl(id: AutomationId): URL {
    return Api.getUrl(`${Automations.path}/${id}`);
  }

  private static getArchiveUrl(id: AutomationId): URL {
    return Api.getUrl(`/api/internal/archive/automation/${id}`);
  }

  public static async getManyWithProject(page: number, options?: Pagination): Promise<Page<AutomationDtoWithProject>> {
    const automations = await Automations.getMany(page, options);
    const result: Page<AutomationDtoWithProject> = {
      content: [],
      page: automations.page,
      pageSize: automations.pageSize,
      totalElements: automations.totalElements,
    };
    for (const automation of automations.content) {
      const project = await Api.locHub.projects.getDto(automation.projectId);
      result.content.push({ ...automation, project });
    }
    return result;
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<AutomationDto>> {
    return LocHub.getJson(Automations.getBaseUrl({ page, ...options }));
  }

  public static async getAll(): Promise<AutomationDto[]> {
    return LocHub.getAll(Automations.path);
  }

  public static async getManyByProject(
    projectId: ProjectId,
    page: number,
    options?: Pagination,
  ): Promise<Page<AutomationDto>> {
    return LocHub.getJson(
      Automations.getBaseUrl({
        page,
        ...options,
        query: new QueryBuilder().addRelation(EntityRelation.PROJECT, projectId).build(),
      }),
    );
  }

  public static async getAllByProject(projectId: ProjectId): Promise<AutomationDto[]> {
    return LocHub.getAll(Automations.path, {
      query: new QueryBuilder().addRelation(EntityRelation.PROJECT, projectId).build(),
    });
  }

  public static async getDto(automationId: AutomationId): Promise<AutomationDto> {
    return LocHub.getJson(Automations.getDetailUrl(automationId));
  }

  public static async create(automation: NewAutomation): Promise<AutomationDto> {
    const result = await LocHub.postJson<NewAutomation, AutomationDto>(Automations.getBaseUrl(), automation);
    queryCache.invalidateQueries(Automations.path);
    return result;
  }

  public static async update(automationId: AutomationId, automation: Partial<AutomationDto>): Promise<AutomationDto> {
    const result = await LocHub.patchJson(Automations.getDetailUrl(automationId), automation);
    queryCache.invalidateQueries(Automations.path);
    return result;
  }

  public static async archive(automationId: AutomationId): Promise<void> {
    await LocHub.post(Automations.getArchiveUrl(automationId));
    queryCache.invalidateQueries(Automations.path);
  }

  public static async delete(automationId: AutomationId): Promise<void> {
    await LocHub.delete(Automations.getDetailUrl(automationId));
    queryCache.invalidateQueries(Automations.path);
  }
}
